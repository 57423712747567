<template>
  <div class="dialog">
    <div class="dialog-header">
      <p class="dialog-title text-uppercase mb-0">Send Announcement</p>
    </div>
    <div class="dialog-content">
      <div class="container-fluid">
        <b-form>
          <b-form-group class="mb-1"
                        label="End Date"
                        label-cols="2">
            <b-form-datepicker local="en"
                               v-model="form.endDate"
                               :close-button="true"
                               :reset-button="true"
                               :date-disabled-fn="endDateDisabled"
            ></b-form-datepicker>
          </b-form-group>

          <b-form-group class="mb-1"
                        label="End Time"
                        label-cols="2">
            <b-form-input type="time"
                          v-model="form.endTime"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Timezone"
                        label-cols="2">
            <b-form-select size="sm"
                           v-model="form.timezone"
                           :options="timezones"></b-form-select>
          </b-form-group>
        </b-form>
      </div>
    </div>
    <div class="dialog-footer mt-2">
      <div class="container-fluid text-right">
        <button class="btn btn-outline-success btn-sm"
                type="submit"
                @click="sendAnnouncement"
                :disabled="$v.$invalid">
          Submit
        </button>
        <button class="btn btn-outline-danger btn-sm ml-1"
                @click="$emit('close')">
          Cancel
        </button>
      </div>
    </div>

    <!-- Loading -->
    <loading :active.sync="isLoading"
             :is-full-page="true"
             :opacity="0.5"
             :width="70"
             :height="70"
             background-color="#000"
             color="#068bcd"
    ></loading>
  </div>
</template>

<script>
  const Loading = () => import('vue-loading-overlay');

  import { required } from 'vuelidate/lib/validators';
  import moment from 'moment-timezone';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name  : 'SendAnnouncement',
    props : {
      data : Object,
    },
    data() {
      return {
        isLoading : false,
        form      : {
          endDate  : null,
          endTime  : null,
          timezone : null,
        },
        timezones : [ {
          value    : null,
          text     : 'Timezone',
          disabled : true,
        }, {
          value : 'America/New_York',
          text  : 'EST/EDT',
        }, {
          value : 'America/Chicago',
          text  : 'CST/CDT',
        }, {
          value : 'America/Denver',
          text  : 'MDT',
        }, {
          value : 'America/Phoenix',
          text  : 'MST No DST',
        }, {
          value : 'America/Los_Angeles',
          text  : 'PST/PDT',
        }, {
          value : 'America/Anchorage',
          text  : 'AKST/AKDT',
        }, {
          value : 'America/Adak',
          text  : 'HDT',
        }, {
          value : 'Pacific/Honolulu',
          text  : 'HST No DST',
        } ],
      }
    },
    components : {
      Loading,
    },
    methods : {

      /**
       * Send Announcement
       */
      sendAnnouncement() {
        this.isLoading = true;

        // set default time zone
        moment.tz.setDefault(this.form.timezone);
        const startDate = new Date();

        console.log(this.form, 'Perro');

        // this.$http.post('api/announcement/send', {
        //   startDate : moment(startDate).tz(this.form.timezone)
        //     .format('YYYY-MM-DD HH:mm'),
        //   endDate        : this.form.endDate + ' ' + this.form.endTime,
        //   timezone       : this.form.timezone,
        //   announcementId : this.data.announcementId,
        //   platform       : this.data.platform,
        //   content        : this.data.content,
        //   filter         : this.data.filter,
        //   title          : this.data.title,
        //   link           : this.data.link,
        //   mediaType      : this.data.mediaType,
        //   playlistId     : this.data.playlistId,
        // }).then(() => {
        //   this.$parent.$emit('reload-table');
        //   this.$emit('close');
        //   this.$notify({
        //     group : 'notif',
        //     type  : 'success',
        //     title : 'Success!',
        //     text  : 'Added Announcement successfully!',
        //   });
        // }).catch(() => {
        //   this.$notify({
        //     group : 'notif',
        //     type  : 'error',
        //     title : 'Failed',
        //     text  : 'Oops! Something went wrong!',
        //   });
        // }).finally(() => {
        //   this.isLoading = false;
        // });
      },

      /**
       * Disable dates in Start Date Calendar Picker
       * @param ymd year month date
       * @param date date as object
       * @return {Boolean}
       */
      endDateDisabled(ymd, date) {
        const today = new Date();
        today.setDate(today.getDate() - 1); // to include current date

        return date < today;
      },
    },
    validations() {
      return {
        form : {
          endDate  : { required },
          endTime  : { required },
          timezone : { required },
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import
    "../../../assets/scss/components/admin/announcement/send-announcement";
</style>